import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title: string;
  description: string;
}

const SEO: React.FC<SEOProps> = ({ title, description }) => (
  <Helmet>
    <title>{title} | PS 217 Parents Association</title>
    <meta name="description" content={description} />
  </Helmet>
);

export default SEO;
