import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome, faInfoCircle, faUsers, faGraduationCap, faEnvelope } from '@fortawesome/free-solid-svg-icons';

interface HeaderProps {
  toggleMobileMenu: () => void;
  isMobileMenuOpen: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleMobileMenu, isMobileMenuOpen }) => {
  const menuItems = [
    { to: '/', label: 'Home', icon: faHome },
    { to: '/about', label: 'About', icon: faInfoCircle },
    { to: '/programs', label: 'Programs', icon: faGraduationCap },
    { to: '/executive-board', label: 'Board', icon: faUsers },
    { to: '/contact', label: 'Contact', icon: faEnvelope },
  ];

  return (
    <header className="bg-gradient-to-r from-primary-dark to-primary-light text-white relative z-50">
      <div className="container mx-auto px-4">
        <div className="navbar justify-start">
          <div className="portrait:block landscape:hidden">
            <button className="btn btn-ghost" onClick={toggleMobileMenu}>
              <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} className="text-xl" />
            </button>
          </div>
          <div className="hidden landscape:flex">
            <ul className="menu menu-horizontal px-1">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <Link 
                    to={item.to} 
                    className="btn btn-ghost normal-case hover:bg-white hover:bg-opacity-20"
                  >
                    <FontAwesomeIcon icon={item.icon} className="mr-2" />
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="portrait:block landscape:hidden fixed inset-0 z-50">
          <div className="bg-black bg-opacity-50 absolute inset-0" onClick={toggleMobileMenu}></div>
          <div className="bg-gradient-to-r from-blue-500 to-green-500 w-64 h-full overflow-y-auto">
            <ul className="menu menu-compact p-4">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <Link 
                    to={item.to} 
                    className="btn btn-ghost justify-start hover:bg-white hover:bg-opacity-20"
                    onClick={toggleMobileMenu}
                  >
                    <FontAwesomeIcon icon={item.icon} className="mr-2" />
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
