import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SEO from './components/SEO';
import Hero from './components/Hero';
import Footer from './components/Footer';
import LoadingIndicator from './components/LoadingIndicator';
import StructuredData from './components/StructuredData';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import './App.css';
import { lazy } from 'react';
import schoolBackground from './assets/school-background.jpg';

// Lazy load components
const About = lazy(() => import('./components/About'));
const Programs = lazy(() => import('./components/Programs'));
const ExecutiveBoard = lazy(() => import('./components/ExecutiveBoard'));
const ContactForm = lazy(() => import('./components/ContactForm'));

const App: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <HelmetProvider>
      <Router>
        <SEO title="PS 217 Parents Association" description="The PS 217 Parents Association is a vibrant community of dedicated parents and guardians working tirelessly to enhance the educational journey of our children at PS 217." />
        <div className="drawer" style={{
          backgroundImage: `url(${schoolBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}>
          <input id="my-drawer-3" type="checkbox" className="drawer-toggle" checked={isMobileMenuOpen} onChange={toggleMobileMenu} /> 
          <div className="drawer-content flex flex-col min-h-screen">
            <Header toggleMobileMenu={toggleMobileMenu} isMobileMenuOpen={isMobileMenuOpen} />
            <main className="flex-grow">
              <a href="#main-content" className="sr-only focus:not-sr-only">Skip to main content</a>
              <Suspense fallback={<LoadingIndicator />}>
                <div id="main-content">
                  <Routes>
                    <Route path="/" element={<Hero />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/programs" element={<Programs />} />
                    <Route path="/executive-board" element={<ExecutiveBoard />} />
                    <Route path="/contact" element={<ContactForm />} />
                  </Routes>
                </div>
              </Suspense>
            </main>
            <Footer />
          </div>
          <div className="drawer-side">
            <label htmlFor="my-drawer-3" className="drawer-overlay" onClick={toggleMobileMenu}></label> 
            <ul className="menu p-4 w-80 h-full bg-base-200">
              <li><a href="/" onClick={toggleMobileMenu}>Home</a></li>
              <li><a href="/about" onClick={toggleMobileMenu}>About</a></li>
              <li><a href="/programs" onClick={toggleMobileMenu}>Programs</a></li>
              <li><a href="/executive-board" onClick={toggleMobileMenu}>Executive Board</a></li>
              <li><a href="/contact" onClick={toggleMobileMenu}>Contact</a></li>
            </ul>
          </div>
        </div>
        <StructuredData />
        <ScrollToTop />
      </Router>
    </HelmetProvider>
  );
};

export default App;