import React, { useState } from 'react';
import landingImg from '../assets/landing.jpg';
import Button from './Button';

interface ChannelInfo {
  icon: string;
  title: string;
  url?: string;
  additionalInfo: string;
}

const Hero: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'connect' | 'channels' | 'contact'>('connect');
  const [hoveredChannel, setHoveredChannel] = useState<number | null>(null);

  const emailAddress = 'ps217parents@gmail.com';
  const emailSubject = 'Request Konstella Access Code';
  const emailBody = 'I would like to request access to Konstella';
  const infoEmailSubject = 'Info on communications channels';
  const infoEmailBody = 'I would like access to the communications channels please';

  const channels: ChannelInfo[] = [
    {
      icon: "fab fa-facebook",
      title: "PS 217 Brooklyn Families Facebook Group",
      url: "https://www.facebook.com/groups/PS217Families/",
      additionalInfo: "Join our Facebook group to connect with other families and stay updated on school events."
    },
    {
      icon: "fab fa-whatsapp",
      title: "Language-specific WhatsApp Groups",
      additionalInfo: "We have groups for Spanish, Bangla, Urdu, and Russian speakers. Contact us to join."
    },
    {
      icon: "fab fa-instagram",
      title: "Principal Bonilla's PS 217 Instagram Page",
      url: "https://www.instagram.com/ps217k/",
      additionalInfo: "Follow our principal's Instagram for school updates and photos."
    },
    {
      icon: "fas fa-mobile-alt",
      title: "PS 217 School App",
      additionalInfo: "Download our school app for news and essential information in over 30 languages."
    }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <div className="hero min-h-screen relative">
        <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${landingImg})` }}></div>
        <div className="hero-overlay bg-black bg-opacity-50 absolute inset-0"></div>
        <div className="hero-content text-center text-neutral-content z-10 px-4 w-full">
          <div className="max-w-md mx-auto">
            <h1 className="mb-5 text-4xl md:text-5xl font-bold leading-tight">PS 217 Parents Association</h1>
            <p className="mb-5 text-sm md:text-base">Welcome to our new website! Join us as we build a vibrant community for the 2024–2025 school year.</p>
            <a href="#content">
              <Button className="text-sm md:text-base">
                Learn More
              </Button>
            </a>
          </div>
        </div>
      </div>

      <div id="content" className="flex-grow bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <section className="mb-16">
            <h2 className="text-4xl font-bold mb-8 text-center text-primary">
              <i className="fas fa-wifi mr-4"></i>
              Stay Connected
            </h2>
            <div className="bg-white rounded-lg shadow-xl p-8">
              <p className="text-center mb-8 text-lg">
                Join our primary communication platform, the KONSTELLA app, for multilingual support and updates on all Parents Association activities.
              </p>
              <div className="text-center">
                <Button
                  className="btn bg-gradient-to-r from-primary-dark to-primary-light text-white"
                  onClick={() => {
                    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
                    window.open(mailtoLink, '_blank');
                  }}
                >
                  Request Konstella Access Code
                </Button>
              </div>
            </div>
          </section>

          <section className="mb-16">
            <h2 className="text-4xl font-bold mb-8 text-center text-primary">
              <i className="fas fa-comments mr-4"></i>
              Communication Channels
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {channels.map((channel, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-xl p-6 hover:shadow-2xl transition-shadow duration-300"
                >
                  <h3 className="text-2xl font-semibold mb-4 flex items-center">
                    <i className={`${channel.icon} mr-3 text-primary`}></i>
                    {channel.title}
                  </h3>
                  <p className="mb-4">{channel.additionalInfo}</p>
                  {channel.url && (
                    <a
                      href={channel.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:text-primary-dark transition-colors duration-300"
                    >
                      Learn More →
                    </a>
                  )}
                </div>
              ))}
            </div>
          </section>

          <section>
            <h2 className="text-4xl font-bold mb-8 text-center text-primary">
              <i className="fas fa-envelope mr-4"></i>
              Contact Us
            </h2>
            <div className="bg-white rounded-lg shadow-xl p-8">
              <p className="text-center mb-8 text-lg">
                For more information or to join our communication channels, please get in touch with the Parent Association.
              </p>
              <div className="text-center">
                <Button
                  className="btn bg-gradient-to-r from-primary-dark to-primary-light text-white"
                  onClick={() => {
                    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(infoEmailSubject)}&body=${encodeURIComponent(infoEmailBody)}`;
                    window.open(mailtoLink, '_blank');
                  }}
                >
                  Contact the Parent Association
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Hero;
