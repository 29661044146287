import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone, faHome, faInfoCircle, faCalendarAlt, faUsers, faEnvelopeOpenText, faCode } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gradient-to-r from-primary-dark to-primary-light text-white pt-12">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="space-y-6">
            <h2 className="text-3xl font-bold">PS 217 Parents Association</h2>
            <p className="text-base">
              Supporting our children's education and school community.
            </p>
            <div className="flex space-x-4">
              {[
                { icon: faFacebookF, href: "https://www.facebook.com/groups/PS217Families/" },
                { icon: faInstagram, href: "https://www.instagram.com/ps217k/" },
                { icon: faTwitter, href: "#" },
              ].map((social, index) => (
                <a key={index} href={social.href} target="_blank" rel="noopener noreferrer" 
                   className="bg-white text-primary-dark hover:bg-primary-light hover:text-white transition-colors p-2 rounded-full">
                  <FontAwesomeIcon icon={social.icon} size="lg" />
                </a>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">
              Quick Links
            </h3>
            <ul className="space-y-3">
              {[
                { text: "Home", to: "/", icon: faHome },
                { text: "About Us", to: "/about", icon: faInfoCircle },
                { text: "Programs", to: "/programs", icon: faCalendarAlt },
                { text: "Board", to: "/executive-board", icon: faUsers },
                { text: "Contact", to: "/contact", icon: faEnvelopeOpenText },
              ].map((link, index) => (
                <li key={index}>
                  <Link to={link.to} onClick={scrollToTop} 
                        className="text-base hover:text-gray-400 transition-colors flex items-center">
                    <FontAwesomeIcon icon={link.icon} className="mr-3 text-primary-light" />
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4">
              Contact Information
            </h3>
            <ul className="space-y-3">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faPhone} className="mr-3 text-primary-light" />
                <a href="tel:+17184346960" className="hover:text-gray-400 transition-colors">(718) 434-6960</a>
              </li>
              <li className="flex items-start">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3 mt-1 text-primary-light" />
                <a href="https://maps.app.goo.gl/BWxYu5Ro3NEds3rRA" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400 transition-colors">
                  1100 Newkirk Avenue, Brooklyn, NY 11230
                </a>
              </li>
            </ul>
            <div className="mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d791.9819355810764!2d-73.96621306898112!3d40.63346409253956!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b334ccef2ab%3A0xeaeacd7033a92f66!2sP.S.%20217%20Colonel%20David%20Marcus%20School!5e0!3m2!1sen!2sus!4v1727782510028!5m2!1sen!2sus"
                width="100%"
                height="150"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="PS 217 Location"
                className="rounded-md shadow-md"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-white border-opacity-20 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm mb-4 md:mb-0">
              &copy; {currentYear} PS 217 Parents Association. All rights reserved.
            </p>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCode} className="mr-2 text-primary-light" />
              <p className="text-sm">
                Created by{' '}
                <a
                  href={`mailto:fernando.r@weboperative.com?subject=ps217pa.org%20looks%20great%2C%20we%20want%20your%20services!`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold hover:text-gray-400 transition-colors"
                >
                  Web Operative, Inc.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
