import React from 'react';
import { Helmet } from 'react-helmet-async';

const StructuredData: React.FC = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "PS 217 Parents Association",
    "url": "https://ps217pa.org",
    "logo": "https://ps217pa.org/logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-718-434-6960",
      "contactType": "customer support"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default StructuredData;
